import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppConfirmComponent } from './app-confirm.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

interface IConfirmData {
  title?: string;
  message?: string;
  showTextField?: boolean;
  showTextAreaField?: boolean;
  placeholderTextField?: string;
  placeholderTextAreaField?: string;
  showSelectField?: boolean;
  placeholderSelectField?: string;
  optionsSelectField?: any;
  isRequired?: Boolean;
  cancelButtonLabel?:any;
  okButtonLabel?:any;
  okButtonName? : any;
  cancelButtonName? : any;
  showCancelButton?:boolean;
  pwdExp?:boolean;
  pwdExpText?:any;
  isTextTransformNone?:boolean;
}

@Injectable()
export class AppConfirmService {

  constructor(private readonly dialog: MatDialog) {
   
   }

  public confirm(data: IConfirmData = {}): Observable<boolean> {
    data.title = data.title || '';
    data.isTextTransformNone = data.isTextTransformNone || false;
    data.message = data.message || '';
    data.isRequired = data.isRequired !== undefined ? 
    data.isRequired : true ;
    data.placeholderTextField = data.placeholderTextField || 'Add more details:';
    data.cancelButtonLabel = data.cancelButtonLabel  ?  data.cancelButtonLabel :'Cancel' ;
    data.okButtonLabel = data.okButtonLabel ? data.okButtonLabel : 'OK';
    data.placeholderTextAreaField = data.placeholderTextAreaField || 'Reason '
    data.placeholderSelectField = data.placeholderSelectField || 'Select an option';
    let dialogRef: MatDialogRef<AppConfirmComponent>;
    if(data.pwdExp === null || data.pwdExp === undefined){
      data.pwdExp = false;
    }
    if(data.cancelButtonName === null || data.cancelButtonName === undefined){
      data.cancelButtonName = 'Cancel';
    }
    if(data.okButtonName === null || data.okButtonName === undefined){
      data.okButtonName = 'OK';
    }
    if(data.showCancelButton === null || data.showCancelButton === undefined){
      data.showCancelButton = false;
    }    
    dialogRef = this.dialog.open(AppConfirmComponent, {
      width: '480px',
      disableClose: true,
      
      data: {
        title: data.title,
        isTextTransformNone: data.isTextTransformNone,
        message: data.message,
        showTextField: data.showTextField,
        showTextAreaField: data.showTextAreaField,
        placeholderTextField: data.placeholderTextField,
        showSelectField: data.showSelectField,
        placeholderSelectField: data.placeholderSelectField,
        optionsSelectField: data.optionsSelectField,
        isRequired: data.isRequired,
        cancelButtonLabel : data.cancelButtonLabel,
        okButtonLabel: data.okButtonLabel,
        cancelButtonName: data.cancelButtonName,
        okButtonName: data.okButtonName,
        showCancelButton:data.showCancelButton,
        pwdExp :data.pwdExp ,
        pwdExpText : data.pwdExpText
      }
    });
    return dialogRef.afterClosed();
  }
}
