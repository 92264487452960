import * as moment from 'moment';
import { ApplicationConstant } from '../constants/app-constant';

export class Utility {
    static filterArrayByKeyAndValue(items: any[], key: any, value: any): any[] {
        return items.filter(function (item) {
            return item[key] === value;
        });
    }

    static filterArrayByKeyAndArray(items: any[], key: any, value: any[]): any[] {
        return items.filter(function (item) {
            return value.includes(item[key]);
        });
    }

    static getObjectFromArrayByKeyAndValue(items: any[], key: any, value: any): any {
        const list = items.filter(function (item) {
            return item[key] === value;
        });
        if (list.length === 1) {
            return list[0];
        }
        if (list.length <= 0) {
            return null;
        }
        if (list.length > 1) {
            throw new Error('The specified array has multiple objects with key: ' + key + ' and value: ' + value);
        }
        return null;
    }

    static isEmpty(value: string): boolean {
        if (value === undefined || value === null || value === '' || value === "") {
            return true;
        }
        return false;
    }
    static transformDateToString(date) {
        if (date && moment.isMoment(date)) {
            return date.format(ApplicationConstant.DEFAULT_DATE_FORMAT);
        } else if (date && !moment.isMoment(date)) {
            return this.transformStringToMomentDate(date).format(ApplicationConstant.DEFAULT_DATE_FORMAT);
        } else {
            return date;
        }
    }
    static transformStringToDate(date: string) {
        moment.locale('en-gb');
        return moment(date, ApplicationConstant.DEFAULT_DATE_FORMAT).toDate();
    }

    static transformStringToMomentDate(date: string) {
        moment.locale('en-gb');
        return moment(date, ApplicationConstant.DEFAULT_DATE_FORMAT);
    }

    static isUserActive(userList, userId) {
        const user = Utility.getObjectFromArrayByKeyAndValue(userList, 'id', userId);
        if (user === null) {
            return false;
        }
        return true;
    }
    setProperty(array: any[], field: string, value) {
        array.forEach((obj: any) => {
            obj[field] = value;
        });
    }

    static getUserIdFromToken():number{
        const token = localStorage.getItem('token');
        return JSON.parse(atob(token.split('.')[1])).userId;
    }

    static isMasterUser():boolean{
        const roleId = JSON.parse(atob(localStorage.getItem('token').split('.')[1])).roleId;
        if(roleId && roleId === 33){
            return true;
        }
        return false;
    }

    static isMasterUserOrSuperUser():boolean{
        const roleId = JSON.parse(atob(localStorage.getItem('token').split('.')[1])).roleId;
        const userType = JSON.parse(atob(localStorage.getItem('token').split('.')[1])).userType;
        if(roleId === 33 || userType === 1){
            return true;
        }
        return false;
    }

    static isSuperUser():boolean{
        const userType = JSON.parse(atob(localStorage.getItem('token').split('.')[1])).userType;
        if(userType === 1){
            return true;
        }
        return false;
    }

    static returnEmptyIfNull(value: string):string{
       if(this.isEmpty(value)){
           return ''
       }
       return value;
    }

}
