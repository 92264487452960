import { NgModule } from '@angular/core';
import { FilterPipe } from './filter.pipe';
import { SafePipe } from './safe.pipe';
import { TruncatePipe } from './truncate.pipe';

@NgModule({
  declarations: [FilterPipe, SafePipe, TruncatePipe ],
  exports: [ FilterPipe, SafePipe, TruncatePipe ]
})
export class FilterPipeModule { }
