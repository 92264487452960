import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-confirm',
  template: `
      <div class="form-container">
      <div mat-dialog-title>
        <div class="page-title-icon">
      <div class="card-header-value" matDialogTitle id="title">
        <span *ngIf="data.isTextTransformNone" style="text-transform: none !important">{{ data.title }}</span>
        <span *ngIf="!data.isTextTransformNone">{{ data.title }}</span>
        <span class="close-alignment">
        <a id="{{data.cancelButtonName}}" (click)="dialogRef.close(false)" *ngIf = "data.isRequired">
          <mat-icon class="mat-tertiary">close</mat-icon>
        </a>
        </span> 
      </div>
      </div>
        <div *ngIf="data.pwdExp">
           {{ data.pwdExpText }}
        </div>
      </div>
      <form>
      <div class="dialog-form-field" [formGroup]="dialogForm">
        <mat-form-field appearance="outline" *ngIf="data.showTextField">
          <mat-label>{{ data.placeholderTextField }} </mat-label>
          <input matInput #inputReason [(ngModel)]="inputReasonTxt" id="text_field" required formControlName="reason">
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="data.showSelectField">
          <mat-label>{{ data.message }} </mat-label>
          <mat-select #selectReason [(ngModel)]="selectReasonOption" required id="select" formControlName="reason">
            <mat-option *ngFor="let option of data.optionsSelectField" [value]="option.id" id="{{option.id}}">
              {{option.reason}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="dialogForm.controls.reason.invalid">
              Reason is required
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="data.showTextAreaField">
          <mat-label>{{ data.placeholderTextAreaField }} </mat-label>
          <textarea matInput #textAreaReason [(ngModel)]="inputReasonTxtArea" id="text_area_field" required rows="3" 
          formControlName="reason" minlength="1">
          </textarea>
          <mat-error *ngIf="dialogForm.controls.reason.invalid && !dialogForm.controls.reason.errors?.pattern && 
              !dialogForm.controls.reason.errors?.minlength && !dialogForm.controls.reason.errors?.maxlength">
              Reason is required
            </mat-error>
          <mat-error *ngIf="dialogForm.controls.reason.errors?.minlength || 
          dialogForm.controls.reason.value?.length > 400 ">
              Minimum 1 and maximum 400 characters allowed
          </mat-error>
          <mat-hint
          *ngIf="dialogForm.controls.reason.invalid && !dialogForm.controls.reason.value ">
          Minimum 1 and maximum 400 characters allowed
          </mat-hint>
          <mat-hint
          *ngIf="dialogForm.controls.reason.valid && dialogForm.controls.reason.value.length ==  (400-1) && dialogForm.controls.reason.value.length !=  (400) ">
          {{400 - dialogForm.controls.reason.value.length}} character left
          </mat-hint>
          <mat-hint
          *ngIf="dialogForm.controls.reason.valid && (400) >= dialogForm.controls.reason.value.length && (400-1) != dialogForm.controls.reason.value.length ">
          {{400 - dialogForm.controls.reason.value.length}} characters left
        </mat-hint>
        </mat-form-field>
      </div>

      <div mat-dialog-actions style="display:flex; margin: 0px">
         <button style="backgroundColor:#004b9c"  type="button" color="primary" mat-flat-button (click)="dialogRef.close(false)" id="{{data.cancelButtonName}}" *ngIf = "data.showCancelButton">
          {{data.cancelButtonName}}
        </button>
        <span style="flex: 1 1 auto;"></span>
        <button *ngIf="data.showSelectField" [disabled]="!selectReasonOption"
        type="button" mat-flat-button color="accent" (click)="dialogRef.close(selectReasonOption)" id="{{data.okButtonName}}">
        {{data.okButtonName}}</button>
        <button *ngIf="data.showTextField" [disabled]="!inputReasonTxt"
        type="button" mat-flat-button color="accent" (click)="dialogRef.close(inputReasonTxt)" id="{{data.okButtonName}}">
        {{data.okButtonName}}</button>
        
        <button *ngIf="data.showTextAreaField" [disabled]="dialogForm.controls.reason.invalid"
        type="button" mat-flat-button color="accent" (click)="dialogRef.close(inputReasonTxtArea)" id="{{data.okButtonName}}">
        {{data.okButtonName}}</button>
        <button *ngIf="!data.showTextField && !data.showSelectField && !data.showTextAreaField"
        type="button" mat-flat-button color="accent" (click)="dialogRef.close(true)" id="{{data.okButtonName}}">
        {{data.okButtonName}}</button>
         &nbsp;
      </div>
      </form>
      </div>
    `,
  styles: [
    `
      h3 {color: #ff8300; font-size: 1.6em; font-weight: 100;}
      .dialog-form-field {display:flex; flex-direction: column; width: 100%;}
      .page-title-icon{ height: 40px;}
      .hide-close-button {display: none}
      `
  ]
})

export class AppConfirmComponent {
  dialogForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AppConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly fb: FormBuilder,

  ) { 
    this.dialogForm = this.fb.group({
      reason: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(400)]],

    });
  }


}
