import { Component, OnInit, HostListener } from '@angular/core';
import { Spinkit } from 'ng-http-loader';
import { BaseUrl } from './framework/constants/url-constants';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { RoutePartsService } from './framework/service/route-parts.service';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  appTitle = BaseUrl.APPTITLE;
  public spinkit = Spinkit;
  pageTitle = '';

  constructor(
    public title: Title,
    private readonly router: Router,
    private readonly routePartsService: RoutePartsService,
    private readonly activeRoute: ActivatedRoute,
  ){

  }
  ngOnInit() {
    this.changePageTitle();
  }


  
  changePageTitle() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
      const routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      if (!routeParts.length) {
        return this.title.setTitle(this.appTitle);
      }

      routeParts.reverse();
      this.pageTitle = routeParts
        .map((part) => part.title)
        .reduce((partA, partI) => `${partI}`);
      this.pageTitle += ` | ${this.appTitle}`;
      this.title.setTitle(this.pageTitle);
    });
  }
}
