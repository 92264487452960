import { environment } from '../../../environments/environment';

export const BaseUrl = {

    PATIENT: environment.apiURL + '/sequation-patient-v3-api',
    USER: environment.apiURL + '/sequation-user-v3-api',
    TEMPLATE: environment.apiURL +'/sequation-genomics-template-v3-api',
    CONTROL: environment.apiURL + '/sequation-genomics-control-v3-api',
    SYSTEM: environment.apiURL + '/sequation-genomics-system-v3-api',
    LOGO: environment.logo,
    APPTITLE: environment.appTitle,
};