import { NgModule } from '@angular/core';
import { AppConfirmService } from './app-confirm.service';
import { AppConfirmComponent } from './app-confirm.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import {MatIconModule} from '@angular/material/icon';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  imports: [ 
  CommonModule, 
  ReactiveFormsModule, 
  FormsModule,
  MatFormFieldModule, 
  MatInputModule, MatButtonModule,
  MatSelectModule, 
  MatIconModule 
  ],
  declarations: [AppConfirmComponent],
  providers: [AppConfirmService,
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}}    
  ],
  entryComponents: [AppConfirmComponent],
  exports: [ AppConfirmComponent ]
})
  export class AppConfirmModule { }
