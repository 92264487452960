export class FileUploadOptions {
    maxFileSize: number;
    showPreviews: boolean;
    preserveFiles: boolean;
    label?: string;
    multiple: boolean;
    acceptAllFiles?: boolean;
    accept?: string[] = [];
    // Format is only applicable which file objects doesn't have type field values
    // Format is to be used by the extension of the file itself (only for inclusion not for exclusion)
    formats?: string[] = []; 
}

export class FileUploadDocumentOptions {
    maxFileSize: number;
    showPreviews: boolean;
    preserveFiles: boolean;
    label?: string;
    multiple: boolean;
    acceptAllFiles?: boolean;

}

export class FileTypes {
    // MS TYPES
    public static DOCX: string = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    public static DOC_RTF: string = 'application/msword';
    public static ODT: string = 'application/vnd.oasis.opendocument.text';
    public static ODP: string = 'application/vnd.oasis.opendocument.presentation';
    public static ODS: string = 'application/vnd.oasis.opendocument.spreadsheet';
    public static XLS: string = 'application/vnd.ms-excel';
    public static XLSX: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    public static PPS_PPT: string = 'application/vnd.ms-powerpoint';
    public static PPTX: string = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
    public static PDF: string = 'application/pdf';
    public static PUBLISHER: string = 'application/vnd.ms-publisher';
    public static TXT: string = 'text/plain';
    public static HTML: string = 'text/html';
    public static JPEG: string = 'image/jpeg';
    public static PNG: string = 'image/png';

    public static ZIP: string = 'application/x-zip-compressed';

    // MEDIA
    public static MP4: string = 'video/mp4';
    public static FLV: string = 'video/flv';
    public static MP3: string = 'audio/mp3';
    public static WAV: string = 'audio/wav';
    public static MPEG = 'audio/mpeg';
}

export class FileFormats {
    public static FLV = 'flv';
}

export class FileError {
    file: File;
    message: string;

    constructor(file: File, message: string) {
        this.file = file;
        this.message = message;
    }
}